import { preprocessFeatureCardVariantData } from "@components/FeatureCardVariant";
import { GridContainer, GridContainerFullBleed, GridWrapper } from '@theme/lib';
import dynamic from "next/dynamic";
import { ReactElement } from "react";
import { Paragraph } from "./__generated__/Paragraph";

const WYSIWYG = dynamic(() => import('@components/WYSIWYG'), {
  ssr: true,
});

const ImpactBlock = dynamic(() => import('@components/ImpactBlock'), {
  ssr: true,
});

const PromoBlock = dynamic(() => import('@components/PromoBlock'), {
  ssr: true,
});

const Infographic = dynamic(() => import('@components/Infographic'), {
  ssr: true,
});

const NewsletterSignUpBlock = dynamic(() => import('@components/NewsletterSignUpBlock'), {
  ssr: true,
});

const ProjectInDetailSlider = dynamic(() => import('@components/ProjectInDetailSlider'), {
  ssr: true,
});

const Accordion = dynamic(() => import('@components/Accordion'), {
  ssr: true,
});
const FeatureCard = dynamic(() => import('@components/FeatureCard'), {
  ssr: true,
});
const Carousel = dynamic(() => import('@components/Carousel'), {
  ssr: true,
});
const DownloadButtons = dynamic(() => import('@components/DownloadButtons'), {
  ssr: true,
});
const SpeciesBlock = dynamic(() => import('@components/SpeciesBlock'), {
  ssr: true,
});
const Images = dynamic(() => import('@components/Images'), {
  ssr: true,
});
const GeneralListing = dynamic(() => import('@components/GeneralListing'), {
  ssr: true,
});
const ImageGallery = dynamic(() => import('@components/ImageGallery'), {
  ssr: true,
});
const InPageTabs = dynamic(() => import('@components/InPageTabs'), {
  ssr: true,
});
const Webform = dynamic(() => import('@components/Webform'), {
  ssr: true,
});
const FourPillars = dynamic(() => import('@components/FourPillars'), {
  ssr: true,
});
const TodayAtZooListing = dynamic(() => import('@components/TodayAtZooListing'), {
  ssr: true,
});
const InfoBlock = dynamic(() => import('@components/InfoBlock'), {
  ssr: true,
});
const FeatureCardVariant = dynamic(() => import('@components/FeatureCardVariant'), {
  ssr: true,
});
const TestimonialBlockQuote = dynamic(() => import('@components/TestimonialBlockQuote'), {
  ssr: true,
});
const EventListing = dynamic(() => import('@components/EventListing'), {
  ssr: true,
});

const renderParagraph = (paragraph: Paragraph, index: number): ReactElement => {
  switch (paragraph.__typename) {
    case "RichTextParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><WYSIWYG {...paragraph} /></GridContainer>
      );
    case "ImpactBlockParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><ImpactBlock {...paragraph} /></GridContainer>
      );
    case "PromoBlockParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><PromoBlock {...paragraph} /></GridContainer>
      );
    case "StatisticsParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><Infographic {...paragraph} /></GridContainer>
      );
    case "NewsletterSignUpBlockParagraph":
      return (
        <NewsletterSignUpBlock key={paragraph.id} {...paragraph} />
      );
    case "ProjectInDetailSliderParagraph":
      return (
        <ProjectInDetailSlider key={paragraph.id} {...paragraph} />
      );
    case "AccordionParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><Accordion {...paragraph} /></GridContainer>
      );
    case "FeatureCardParagraph":
      return (
        <FeatureCard key={paragraph.id} {...paragraph} />
      );
    case "CarouselParagraph":
      return (
        <div key={paragraph.id} style={{ overflow: 'hidden' }}>
          <GridContainer className={paragraph.__typename}>
            <Carousel {...paragraph} />
          </GridContainer>
        </div>
      );
    case "DownloadsParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><DownloadButtons {...paragraph} /></GridContainer>
      );
    case "SpeciesParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><SpeciesBlock {...paragraph} /></GridContainer>
      );
    case "ImagesParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}><Images {...paragraph} /></GridContainer>
      );
    case "GeneralListingParagraph":
      return (
        <GridContainer className={paragraph.__typename} key={paragraph.id}>
          <GeneralListing callToAction={paragraph.listingCTA} title={paragraph?.listingTitle || undefined} {...paragraph} />
        </GridContainer>
      );
    case "ImageGalleryParagraph":
      return (
        <GridContainerFullBleed className={paragraph.__typename} key={paragraph.id}>
          <GridWrapper alignment={"full-width"}>
            <ImageGallery key={`gallery-${paragraph.id}`} cards={paragraph.gallerySlides} {...paragraph} />
          </GridWrapper>
        </GridContainerFullBleed>
      );
    case "InPageTabsParagraph":
      return (
        <div className={paragraph.__typename} key={paragraph.id}>
          <InPageTabs {...paragraph} />
        </div>
      );
    case "WebformParagraph":
      return (
        <Webform key={paragraph.id} {...paragraph} />
      );
    case "PillarParagraph":
      return (
        <FourPillars key={paragraph.id} {...paragraph} />
      );
    case "TodayAtTheZooListingParagraph":
      return (
        <TodayAtZooListing key={paragraph.id} {...paragraph} />
      );
    case "InfoBlockParagraph":
      return (
        <InfoBlock key={paragraph.__typename} {...paragraph} />
      );
    case "FeatureCard2_3Paragraph":
      return (
        <FeatureCardVariant key={paragraph.id} {...preprocessFeatureCardVariantData(paragraph)} />
      );
    case "TestimonialsParagraph":
      return (
        <TestimonialBlockQuote key={paragraph.id} {...paragraph} />
      );
    case "EventListingParagraph":
      return(
        <EventListing key={paragraph.id} {...paragraph} />
      )
    default:
      // @ts-ignore: Handling for unimplemented paragraphs
      return (<></>);
  }
};

export type SlicesProps = {
  content: Paragraph[];
};

const Slices = (content?: Paragraph[]): ReactElement => {
  if (content) {
    return (<>{Object.keys(content).map((key, i) => renderParagraph(content[i], i))}</>);
  }

  return <></>;
};

export default Slices;
